const setFeatureToggles = require('../services/featureToggles')

const hostedZoneId = 'Z2114D6TD4C7GZ'
const domainName = 'uat.zgallerie.com'
const environmentName = 'production'
const PublicCdnDnsName = 'uat.zgalleriecdn.com'
const CDN_PREFIX = 'https://uat.zgallerie.com'

module.exports = {
  ENABLE_CITIZENS: 'true',
  CDN_PREFIX,
  // In reason of changing any CitizensPay configuration, be sure to make changes
  // also on Shogun for t-citizens-pay landing page
  CITIZENS_SCRIPT: 'https://toolbox.jifiti.com/Widgets/1.0.4/jifiti-widget.min.js',
  CITIZENS_WIDGET_KEY: '38ac1147c33545ec8c5a4aa9a2951d72',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDkkr7TBQj4KS2gxkt1IXV4X1gI_JDehvU',
  AWS_REGION: 'us-east-1',
  AWS_PROFILE: 'zgallerie',
  CHECKOUT_DOMAIN: '/checkout-frame',
  CHECKOUT_IDENTIFIER: 'zgallerie-production',
  DOMAIN_NAME: domainName,
  ENVIRONMENT_NAME: environmentName,
  CONFIG_NAME: 'zgallerie',
  CONFIG_FILE: 'production.json',
  RECREATE_URL: null,
  READONLY_BC_TOKEN: 'niej95zavmy6rvabrucjzsq8mymvm3t',
  CONTENT_PREVIEW: false,
  CONTENT_API: 'https://vkjwfy457e.execute-api.us-east-1.amazonaws.com/Prod/v1',
  INVENTORY_API: 'https://pat.zgallerie.com',
  PARKY_API: '/parky',
  SEARCH_API_V2: 'https://txhyvbzzjd.execute-api.us-east-2.amazonaws.com/Prod',
  SEARCH_ORIGIN: 'https://prod-parky-distribution.zgallerie.com',
  SHOP_ORIGIN: 'https://uat.zgallerie.com',
  CONTENT_SQUARE_ID: '727bb53d4033f',
  PRODUCT_INDEX: 'zg_products',
  CATEGORY_INDEX: 'zg_categories',
  PAGE_INDEX: 'zg_pages',
  TIKTOK_PIXEL_ID: 'CEQ1V1JC77U8BHMFJIIG',
  FACEBOOK_PIXEL_ID: '965123473582294',
  SHOP_HASH: 'whvhnri10h',
  LEASECO_ID: '4dbf70f8-e967-46b6-98e0-507d2c2b80c3',
  GTM_TRACKING_ID: 'GTM-WZW9GNZ',
  GA_TRACKING_ID: 'G-XH6Z5GG2WH',
  DeployParameterOverrides: JSON.stringify({
    HostedZoneId: hostedZoneId,
    DomainName: domainName,
    EnvironmentName: environmentName,
    StaticsBucketDnsName: 'statics-zgallerie-ssr-next-us-east-1-746571731253-uat.s3.amazonaws.com',
    PublicCdnDnsName,
    PublicCdnHostedZoneId: 'Z00276071KG0P5O15FL7M',
  }),
  BOPUS_FIELD: 'field_30',
  APPLE_PAY_FIELD: 'field_48',
  CATEGORY_PAGE_VERSION_FIELD: 'field_46',
  BOPUS_STORE_FIELD: 'field_32',
  ZGOLD_REDEEMED_FIELD: 'field_40',
  RESERVATION_NUMBER_FIELD: 'field_34',
  DTP_NUMBER_FIELD: 'field_36',
  CHECKOUT_VERSION_FIELD: 'field_38',
  STORE_CREDITS_APPLIED_FIELD: 'field_42',
  IS_CITIZENS_PAY_ORDER_FIELD: 'field_44',
  FEATURE_TOGGLES: setFeatureToggles(environmentName),
  VITALS_LOGGING_API: 'https://09kspz6ic5.execute-api.us-east-1.amazonaws.com/Prod',
  SPIFFY_URL: '127.0.0.1:3000',
  GA_DIMENSION: 'dimension17',
  CHECKOUT_EXPERIMENT_ID: 'YxeHn4CZQcuVyY2CNIvZGw',
  PLACES_KEY: 'AIzaSyBWw0YpBr5L2IZwkjPErjDnZrqIi2Iw-8c',
  LISTRAK_ID: 'EmCC5iEfX18F',
  AW_TRACKING_ID: 'AW-1044894252',
  BOUNCEX_ID: '4315',
  CONTENTFUL_ACCESS_TOKEN: '6hsS2QRVGZLeur4b7GMuNtqAZJ9QBCdnu7DcO-WLTwQ',
  CONTENTFUL_SPACE_ID: 'p62egiw5uahp',
  CONTENTFUL_ENVIRONMENT: 'master',
  EXTEND_ID: '56c1e431-8bb0-4e91-951e-ecd0a936dac7',
  EXTEND_ENVIRONMENT: 'production',
  CITIZENS_PAY_EXPERIMENT_ID: 'HvQ_ItYNSBKLqRKpIgOM5w',
  SHOP_TOKEN_SCRIPT: 'https://web-js.bigshoptoken.com/main.js',
  SENTRY_DSN: 'https://e17ae7752d684301a9f05c3640902566@o32694.ingest.sentry.io/4505110708420608',
  GraphQLStoreFrontJWT: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOjEsImNvcnMiOlsiaHR0cHM6Ly93d3cuemdhbGxlcmllLmNvbSJdLCJlYXQiOjIwNTE4MDg4OTcsImlhdCI6MTY0NTgxMTMzNSwiaXNzIjoiQkMiLCJzaWQiOjEwMDA2MDI3MTUsInN1YiI6Im1oNXI1dXQybzg1MXI4OXhjeDB0bmFuajd5bTAwd3giLCJzdWJfdHlwZSI6MiwidG9rZW5fdHlwZSI6MX0.S-5WBysQceUDo760ID7xJaCq2ypdXgvEuOAG-Q2gUyrGBiqfms9sXb12EGbvKcyydfbIWUIG6kqOu5HXHjuYtA', // Unix 2051808897 Sun Jan 07 2035 18:54:57 GMT+0000
  VWO_SETTINGS: {
    ACCOUNT_ID: 681563,
    TOLERANCE: 2000,
    LIBRARY_TOLERANCE: 2500
  },
  OPTIMIZE_CONTAINER_ID: 'GTM-PQD2DJ4',
  PAYPAL_ID: 'AfiXzZX5ikr8xD3feKYKPPTfR02LHP7IeHGXr7m3_TcP0HIa3YQpSxw66xhNjwlHRZBbpNAjw5tMlTJA',
  CSC_SHOPFRONT_API: 'api.cscshopfront.com',
  CSC_PAYMENTS_HOST: 'api.cscpaymentsservices.com',
  CSC_PAYMENTS_PK: 'pk_live_51MkVrfLTg5aij8f1v92EG5cWIKJ4xihsgcNdlNYcRnZOstGWASHsknOCV9L6unFDSZvLqP5f4Yqr45BOXOLjdMd4003OaChUOg',
  CSC_PAYMENTS_GATEWAY: 'zg-production-stripe',
  CSC_PAYPAL_GATEWAY: 'zg-production-paypal',
  CSC_PAYMENTS_SHOP: 'zg-production',
  CSC_IAM_AUD: 'tbu0x1gtrefne9osp2c156ufwon909a',
  CSC_IAM_REGISTERED_IDP: 'zg-production-registered',
  CSC_IAM_GUEST_IDP: 'zg-production-guests',
  CSC_IAM_REGISTERED_ROLE: 'SignedInProductionZgShopFrontCustomer',
  CSC_IAM_GUEST_ROLE: 'GuestProductionZgShopFrontCustomer',
  GUEST_TOKEN_NAME: 'guestToken',
  CSC_IAM_ACCOUNT: '010100111001',
  SHOP_TOKEN_SKU: '800250025',
  SHIPPEDSHIELD_EXPERIMENT_ID: 'vsZk1ceqR0WAdg2xxZwasA',
  USE_LOCAL_CSC_CREDENTIALS: false,
  DMPFiltersDataSetName: 'zg-category-page-filters',
  DMPMainDataSetName: 'zg-index-001',
  OPEN_SEARCH_ORIGIN: 'https://vpc-zgallerie-search-production-bhqc7wxfzqjh6ivjcghm7fvruy.us-east-2.es.amazonaws.com',
}
